import client from "./client";

const getReadyOtherPlace = (id) => {
  return client
    .get(`/ready-other-place/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => error.response);
};

const getReadyOtherPlaces = (filter) => {
  return client
    .get(`/ready-other-places`, filter)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => error.response);
};

const createReadyOtherPlace = (data) => {
  return client
    .post("/ready-other-place", data)
    .then((response) => {
      return response;
    })
    .catch((error) => error.response);
};

const updateReadyOtherPlace = (id, data) => {
  return client
    .put(`/ready-other-place/${id}`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => error.response);
};

const deleteReadyOtherPlaces = (data) => {
  return client
    .put(`/ready-other-place`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

const readyOtherPlaceAPI = {
  getReadyOtherPlace,
  createReadyOtherPlace,
  updateReadyOtherPlace,
  deleteReadyOtherPlaces,
  getReadyOtherPlaces,
};

export default readyOtherPlaceAPI;
